<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        ASIGNACIONES
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <AssignmentTable :headers="headers" :boards="boards">

       </AssignmentTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import AssignmentTable from '@/components/AssignmentTable.vue' 
export default {
  name: 'Assignment',
  components: {
    AssignmentTable
  },
  data() {
    return {
      headers: {
        assignment: [
          {
            text: 'Guía turístico',
            align: 'start',
            value: 'supplier_guideId.number',
          },
          {
            text: 'Conductor',
            align: 'start',
            value: 'supplier_vehicleId.number',
          },
          {
            text: 'Vehículo',
            align: 'start',
            value: 'vehicle',
          },
          {
            text: 'Tour',
            align: 'start',
            value: 'invoice_detailId.booking_reference',
          },
          {
            text: 'Descripción',
            align: 'start',
            value: 'description',
          },
          {
            text: 'Monto',
            align: 'start',
            value: 'amount',
          },
          {
            text: 'Impuesto',
            align: 'start',
            value: 'tax_assignmentId.rate',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        invoice_detail: [
          {
            align: 'center',
            text: 'No.',
            value: 'assignment.id'
          },
          {
            align: 'center',
            text: 'Tour',
            value: 'tour'
          },
          {
            align: 'center',
            text: 'Fecha',
            value: 'fecha'
          },
          {
            align: 'center',
            text: 'Descripcion',
            value: 'descripcion'
          },
           {
            align: 'center',
            text: 'Reserva',
            value: 'reserva'
          },
           {
            align: 'center',
            text: 'Factura',
            value: 'factura'
          },
          {
            align: 'center',
            text: 'Hora',
            value: 'hora'
          },
          {
            align: 'center',
            text: 'Ruta',
            value: 'ruta'
          },
          
          {
            align: 'center',
            text: 'Pax',
            value: 'pax'
          },
          {
            align: 'center',
            text: 'Tarifa',
            value: 'tarifa'
          },
          {
            align: 'center',
            text: 'Acciones',
            value: 'actions'
          },
        ]
      },
      boards: {
        assignment: 'assignment',
        invoice_detail: 'invoice_detail'
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    next(localStorage.removeItem("search"))
  }
}
</script>

<style>

</style>