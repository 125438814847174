<template>
  <div>
    <!-- Data Table invoice Details -->
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col cols="12" sm="12" md="3" >
                <div class="d-flex justify-space-around align-center">
                  <h5>Asignaciones</h5>
                 
                   <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="info"
                        rounded
                        small
                        v-on="on"
                        v-bind="attrs"
                        @click="dialogAsigByDay = true"
                      >
                        Day
                      </v-btn>
                    </template>
                    <span>Escoger dia a mostrar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        fab
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="openExcelAtv"
                      >
                        <v-icon dark >mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <span>Reporte ATV asignados</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        fab
                        x-small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        @click="openOpcionesRepAsigPDF"
                      >
                        <v-icon dark >mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Asignaciones General/individual</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="assignmentSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <!-- <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.assignment)"
                >
                  Crear Asignación
                </v-btn>
              </v-col> -->
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table
              dense
              :headers="headers.invoice_detail"
              :items="invoiceDetail_records"
              :items-per-page="-1"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :search="assignmentSearch"
              :loading="loadingAssignmentTable"
              loading-text="Loading... Please wait"
              no-data-text="Escojer una de las opciones para listar los datos"
              no-results-text="Escojer una de las opciones para listar los datos"
              sort-by="hora"
            >
              <template v-slot:item.tour="{ item }">
                <v-chip
                  small
                  :color="getColor(item.tour)"
                  dark
                >
                  {{ item.tour }}
                </v-chip>
              </template>

               <template v-slot:item.actions="{ item }">
                <!-- Botom Crear Asignacion -->
                <div class="d-flex">
                  <v-btn 
                    v-if="!item.assignment && item.item.calendar_operationId"
                    icon 
                    color="primary" 
                    @click="openCreateForm(item, item.id)"
                    >
                    <v-icon>
                      mdi-clipboard-account-outline
                    </v-icon>
                  </v-btn>
                  
                  <!-- boton Editar Asignacion -->
                  <v-btn 
                    v-if="item.assignment"
                    icon
                    color="warning" 
                    @click="editItem(item)"
                    >
                    <v-icon  >
                      mdi-clipboard-text
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    :href="item.item.calendar_operationId.htmlLink"
                    target="_blank"
                    v-if="item.item.calendar_operationId"
                    >
                    <v-icon
                      color="green">
                      mdi-clipboard-check
                    </v-icon>
                  </v-btn>
                  <v-icon
                    v-if="!item.item.calendar_operationId"
                    color="red">
                    mdi-close-box
                  </v-icon>
                </div>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Assignment Form -->
    <v-row justify="center" >
      <v-form ref="createAssignmentForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogAssignment" persistent max-width="600px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Asignación ${createAssignmentForm.id}` : `Crear Asignación` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container class="py-1">
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createAssignmentForm.invoice_detail"
                      item-value="id"
                      dense
                      :items="invoiceDetail_records"
                      :item-text="tourText"
                      label="Tour"
                      :disabled="disableSelected"
                      :rules="[createFormRules.required]"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-switch
                      class="pb-5 mt-1"
                      v-model="switchCreateForm"
                      label="Borrar opciones previas del titulo de asignacion"
                      color="success"
                      value="success"
                      hide-details
                      @change="cleanSubject(switchCreateForm)"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Agencia"
                      v-model.trim="createAssignmentForm.agency"
                      @input="addAgency(createAssignmentForm.agency)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" v-if="AsigmentATV">
                    <v-text-field
                      dense
                      color="green"
                      filled
                      autofocus
                      placeholder="Columna detalle reporte"
                      v-model.trim="createAssignmentForm.detalle"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createAssignmentForm.supplier_driver"
                      item-value="id"
                      dense
                      :items="supplierDriver_records"
                      :item-text="supplierText"
                      label="Conductor"
                      clearable
                      @input="addInformationDriver"
                      :rules="[v => !!v || 'Campo obligatorio']"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createAssignmentForm.supplier_guide"
                      item-value="id"
                      dense
                      :items="supplierGuide_records"
                      :item-text="supplierText"
                      label="Guía turístico"
                      clearable
                      @input="addInformationGuide"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createAssignmentForm.info.supplier_add"
                      item-value="id"
                      dense
                      small-chips
                      multiple
                      :items="supplierGuide_records.filter(f=> f.id != createAssignmentForm.supplier_guide)"
                      :item-text="supplierText"
                      label="Guía Adicionales"
                      clearable
                      @input="addAditionalGuides"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createAssignmentForm.supplier_comi"
                      item-value="id"
                      dense
                      :items="supplierComi_records"
                      :item-text="supplierText"
                      label="Comisionista/Proveedor x pagar"
                      clearable
                      @input="isComisionist(createAssignmentForm.supplier_comi)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Monto"
                      v-model.number="createAssignmentForm.amount"
                      type="number"
                      min="0"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createAssignmentForm.vehicle"
                      item-value="id"
                      dense
                      :items="vehicles"
                      :item-text="vehicleText"
                      label="Vehiculo"
                      clearable
                      @input="addInformationVehicle"
                      :rules="[v => !!v || 'Campo obligatorio']"
                    ></v-autocomplete>
                  </v-col>
                  <!-- <v-col cols="12" md="4">
                    <v-autocomplete
                      class="text-caption"
                      v-model="createAssignmentForm.tax_assignment"
                      item-value="id"
                      dense
                      disabled
                      :items="tax_records"
                      item-text="rate"
                      label="Impuesto"
                      clearable
                    ></v-autocomplete>
                  </v-col> -->
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      :key="descriptionKey"
                      dense
                      disabled
                      label="Preliminar que se vera en el calendario -- Agencia | Pax (Driver/Vehiculo/Guia) "
                      v-model.trim="createAssignmentForm.previewDescription"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea
                      rows="2"
                      dense
                      label="Descripción en la asignacion"
                      v-model.trim="createAssignmentForm.description"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.assignment)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.assignment)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.assignment)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

   

    <!-- DIALOG DELETE ASSIGNMENT -->
    <v-dialog v-model="dialogDeleteAssignment" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta asignación?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.assignment)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.assignment)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo caga servicios por fecha -->
    <v-dialog
      v-model="dialogAsigByDay"
      width="500"
    >
      <v-card rounded="xl" :loading="loadingAssignmentTable" :disabled="loadingAssignmentTable">
        <v-card-title class="text-h5 grey lighten-2">
          Fecha para asignaciones
        </v-card-title>

        <v-card-text class="pa-2">
          <v-row dense align="center" justify="center">
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                v-model="menuDateAsig"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateAsigByDay"
                    label="Escoger fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateAsigByDay"
                  @input="menuDateAsig = false"
                ></v-date-picker>
              </v-menu>
            </v-col>    
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="getData('invoice_detail', dateAsigByDay)"
          >
            Cargar Servicios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <!-- Dialogo opciones pdf -->
    <v-dialog
      v-model="dialogRepAsigPdf"
      width="500"
    >
      <v-card rounded="xl" :loading="loadingPdf" :disabled="loadingPdf">
        <v-card-title class="text-h5">
          Reporte Asignaciones del dia
        </v-card-title>
        <v-card-subtitle class="py-3 font-weight-bold">
          **Recuerde que solo saldra la informaciona de aquellos servicios asignados.
        </v-card-subtitle>

        <v-card-text>
          <v-row dense align="center">
            <v-col
              cols="12"
              md="6"
            >
              <v-radio-group
                v-model="optionsPDF"
                column
              >
                <v-radio
                  label="General"
                  color="info"
                  value="general"
                ></v-radio>
                <v-radio
                  label="Individual"
                  color="success"
                  value="individual"
                  @click="lookGuiasChoferes"
                ></v-radio>
                <v-radio-group
                  v-if="optionsPDF == 'individual'"
                  v-model="OptionPdfTipo"
                  row
                >
                  <v-radio
                  label="Chofer"
                  color="success"
                  value="chofer"
                  @click="lookGuiasChoferes"
                ></v-radio>
                <v-radio
                  v-if="optionsPDF == 'individual'"
                  label="Guia"
                  color="success"
                  value="guia"
                  @click="lookGuiasChoferes"
                ></v-radio>
                </v-radio-group>

                <v-select
                  :disabled="optionsPDF ==  'general'"
                  :label="OptionPdfTipo == 'chofer' ? `Choferes` : 'Guias'"
                  :items="itemsChoferesGuias"
                  item-value="id"
                  item-text="first_name"
                  v-model="selectPDfChoferGuia"
                  autocomplete
                  hide-details
                  @input="lookName(selectPDfChoferGuia)"
                ></v-select>
                <!-- <v-select
                  :disabled="optionsPDF == 'individual' ? false : true"
                  label="label"
                  :items="itemsChoferesGuias"
                  v-model="selectPDfChoferGuia"
                  autocomplete
                ></v-select> -->
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="openAssignmentPdf"
          >
            Generar Reporte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {axiosB, axiosN506} from '../api/axiosBd'
import { saveAs } from 'file-saver'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      switchCreateForm: false,
      descriptionKey: 0,
      dialogAsigByDay: false,
      menuDateAsig: false,
      dateAsigByDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      //loadings
      isLoading: false,
      loadingAssignmentTable: false,
      //dialog delete
      dialogDeleteAssignment: false,
      //search
      assignmentSearch: '',
      //records
      assignment_records: [],
      supplierGuide_records: [],
      supplierVehicle_records: [],
      supplierDriver_records: [],
      supplierComi_records: [],
      invoiceDetail_records: [],
      tax_records: [],
      vehicles: [],
      //form
      disableSelected: false,
      btnEdithVisible: false,
      valid: false,
      dialogAssignment: false,
      createAssignmentForm: {
        info: {
          supplier_add: []
        }
      },
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.'
      },
      selectedRecord: null,
      addInformation: {},
      retrievedObject: '',
      emailAttendent: {
        driver: '',
        comisionist: '',
      },
      AsigmentATV: false,
      OptionPdfTipo: 'chofer',
      dialogRepAsigPdf: false,
      optionsPDF: 'general',
      itemsChoferesGuias: [],
      selectPDfChoferGuia: '',
      loadingPdf: false,
      loadingExcel: false
    }
  },
  computed: {

  },
  methods: {

    cleanSubject(state){
      if (state){
        console.log(this.createAssignmentForm)
        if (this.createAssignmentForm.previewDescription.includes('|')){
          const splitPreview = this.createAssignmentForm.previewDescription.split('|')
          this.createAssignmentForm.previewDescription = `${splitPreview[0].trim()} | ${splitPreview[1].split('(')[0].trim() }`
          this.createAssignmentForm.beforeDescription = `${splitPreview[0].trim()} | ${splitPreview[1].split('(')[0].trim() }`
          this.createAssignmentForm.supplier_driver = null
          this.createAssignmentForm.supplier_guide = null
          this.createAssignmentForm.supplier_comi = null
          this.createAssignmentForm.vehicle = null
        } else {
          const description =  this.createAssignmentForm.item.calendar_operationId.summary.split(' ')
          console.log(description)
          this.createAssignmentForm.previewDescription = `${description[0].trim()} ${description[1].trim() != 'xAsignar' ? description[1].trim(): ''}`
          this.createAssignmentForm.beforeDescription = `${description[0].trim()} ${description[1].trim() != 'xAsignar' ? description[1].trim(): ''}`
          console.log(this.createAssignmentForm.previewDescription)
        }
      }
    },
    getColor (tour) {
      if (tour == 'R-T-IN' || tour == 'One-IN') return 'blue lighten-1'
      else if (tour == 'R-T-OUT' || tour == 'One-OUT') return 'red'
      else if (tour == 'Tra' ) return 'primary'
      else if (tour == 'ATV') return 'orange'
      else if (tour == 'Sco') return 'purple'
      else return 'green'
    },
    
    isComisionist(comisionist){
      console.log(comisionist)
      if (comisionist) {
        if (this.btnEdithVisible){
          comisionist = this.supplierComi_records.find(comi=> comi.id == comisionist)
          if (!comisionist.emailId.email) {
            alert('Comisionista o preveedor no tiene email, vaya al modulo de proveedor y corriga este problema antes de continuar.')
          } else {
            this.emailAttendent.comisionist = comisionist.emailId.email
            this.createAssignmentForm.supplier_driver = null
            this.createAssignmentForm.supplier_guide = null
            this.createAssignmentForm.vehicle = null
            const preview = this.createAssignmentForm.previewDescription.split('(')
            this.descriptionKey ++
            this.createAssignmentForm.previewDescription = `${preview[0].trim()} (${comisionist.short_name})`
            this.createAssignmentForm.beforeDescription = `${preview[0].trim()} (${comisionist.short_name})`
          }
          
        } else {
          comisionist = this.supplierComi_records.find(comi=> comi.id == comisionist)
          console.log(comisionist)
          if (!comisionist.emailId.email) {
            alert('Comisionista o preveedor no tiene email, vaya al modulo de proveedor y corriga este problema antes de continuar.')
          } else {
            this.emailAttendent.comisionist = comisionist.emailId.email
            this.createAssignmentForm.supplier_driver = null
            this.createAssignmentForm.supplier_guide = null
            this.createAssignmentForm.vehicle = null
            const preview = this.createAssignmentForm.previewDescription.split('(')
            this.descriptionKey ++
            this.createAssignmentForm.previewDescription = `${preview[0].trim()} (${comisionist.short_name})`
            this.createAssignmentForm.beforeDescription = `${preview[0].trim()} (${comisionist.short_name})`
            console.log(preview)
          }
        }
      } else {
        console.log('quita la comision')
        this.descriptionKey ++
        this.createAssignmentForm.previewDescription = this.createAssignmentForm.previewDescription.split('(')[0]
        this.createAssignmentForm.beforeDescription = this.createAssignmentForm.previewDescription.split('(')[0]
      }

    },
    async updateSummaryCalendar(formUpdate){
      console.log(formUpdate)
      let dataCalendar = {}
      await this.$store.dispatch('verifyTokenPy')
      axiosB(`extractor/api/506transfers/BuscarEvento`,{
        method: 'POST',
        data: {id: formUpdate.item.calendar_operationId._id},
        headers: { Authorization: `Bearer ${this.$store.state.accessTokenPy}`}
      })
      .then((result) => {
        console.log(result.data.data)
        dataCalendar =  result.data.data
        dataCalendar.summary = formUpdate.calendarSumary
        dataCalendar.attendees =[]
        if (this.emailAttendent.driver) dataCalendar.attendees.push({email: this.emailAttendent.driver, displayName: 'Driver'})
        if (this.emailAttendent.comisionist) dataCalendar.attendees.push({email: this.emailAttendent.comisionist, displayName: 'Comi/Proveedor'})

        if (dataCalendar.description.search('Asig No.') == 0) {
          dataCalendar.description = dataCalendar.description.slice(dataCalendar.description.length)
        }
        if (dataCalendar.description.search('Asig No.') > 0) {
          dataCalendar.description = dataCalendar.description.slice(0, dataCalendar.description.indexOf('\nAsig No.'))
          console.log(dataCalendar.description)
          console.log(formUpdate.item.special_requirements)
        }

        // console.log(formUpdate.id, dataCalendar.description, dataCalendar.description.indexOf('Asig No.'))

        dataCalendar.description = `${dataCalendar.description}
Asig No. ${formUpdate.id}
${formUpdate.form.description ? '==Notas de la asignacion:' : ''}
${formUpdate.form.description}`
        formUpdate.dataCalendar = dataCalendar

        console.log(dataCalendar.description)
        console.log(dataCalendar)

        this.updateEventCalendar(formUpdate)

      }).catch((err) => {
        alert(err.resonse)
      })

      //actualizamos el summary del calendario

      // console.log(dataCalendar)

    },
    async updateEventCalendar(formUpdate){
      // revisar aqui
      let createFormEvent = {id: formUpdate.item.calendar_operationId._id ,form: formUpdate.dataCalendar, action: 'update'}
      await this.$store.dispatch('verifyTokenPy')
      axiosB(`extractor/api/506transfers/ActualizarEvento`,{
        method: 'POST',
        data: createFormEvent,
        headers: { Authorization: `Bearer ${this.$store.state.accessTokenPy}`}
      })
      .then((result) => {
        console.info(result)
        this.$notify({
          title: "Modificacion Calendario",
          message: "El Summary del calendario fue modificado correctamente",
          type: "success",
          duration: 5500,
        })
        this.updateSummaryBd(formUpdate)
      }).catch((err) => {
        alert(err.resonse)
      })
    },
    async updateSummaryBd(formUpdate){
      //actualizamos bd
      let formDataBase = ''
      await this.$store.dispatch('verifyToken')
      axiosN506(`calendar_operation/update`,{
        method: 'PUT',
        data: { id: formUpdate.item.calendar_operationId.id, summary: formUpdate.calendarSumary },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}`}
      })
      .then((result) => {
        this.$notify({
          title: "Modificacion Calendario",
          message: "registro calendar operations fue modificado correctamente en la base de datos",
          type: "success",
          duration: 5500,
        })
        this.getData(this.retrievedObject.board, this.retrievedObject.params)
        this.isLoading = false
        
        this.emailAttendent = {
          driver: '',
          comisionist: '',
        }
      }).catch((err) => {
        alert(err.resonse)
        this.isLoading = false
      })


      this.isLoading = false
    },
    async summitForm(method, board){
      this.isLoading = true
      this.loadingAssignmentTable = true
      let error = false
      let form = {}
      let formUpdate = {}

      if (board == this.boards.assignment) {
        if (!this.$refs.createAssignmentForm.validate()) {
          error = true
        } else {
          this.createAssignmentForm.amount = parseFloat(this.createAssignmentForm.amount)
          // if (parseInt(this.createAssignmentForm.supplier_driver) == 16) this.createAssignmentForm.vehicle = 7
          // if (parseInt(this.createAssignmentForm.vehicle) == 7) this.createAssignmentForm.supplier_driver = 16
          // if (parseInt(this.createAssignmentForm.vehicle) == 7 || parseInt(this.createAssignmentForm.supplier_driver) == 16) {
          //   this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.item.traveler_names.toUpperCase()} SIN TRANSPORTE`
          // }
          form = {...this.createAssignmentForm}
          formUpdate.form = form
          formUpdate.calendarSumary = form.previewDescription
          formUpdate.item = form.item
          if(this.btnEdithVisible) formUpdate.id = this.createAssignmentForm.id

        }
      }
      if (!error) {
        await this.$store.dispatch('verifyToken')
        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((result) => {
            formUpdate.id = result.data.data.id
            this.updateSummaryCalendar(formUpdate)
            this.getData(this.retrievedObject.board, this.retrievedObject.params)
            // this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.updateSummaryCalendar(formUpdate)
            this.getData(this.retrievedObject.board, this.retrievedObject.params)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item){
      console.log(item)
      if (item.tour == 'ATV') this.AsigmentATV = true
      this.dialogAssignment = true
      this.btnEdithVisible = true
      // this.createAssignmentForm = {}
      // this.selectedRecord = item.id

      this.createAssignmentForm.item = item.item
      this.createAssignmentForm.id = item.assignment.id
      this.createAssignmentForm.info.supplier_add = item.assignment.info.supplier_add
      this.createAssignmentForm.supplier_guide = parseInt(item.assignment.supplier_guide)
      this.createAssignmentForm.supplier_comi = parseInt(item.assignment.supplier_comi)
      this.createAssignmentForm.supplier_driver = parseInt(item.assignment.supplier_driver)
      this.createAssignmentForm.vehicle = parseInt(item.assignment.vehicle)
      this.createAssignmentForm.invoice_detail = item.id
      this.createAssignmentForm.description = item.assignment.description
      this.createAssignmentForm.detalle = item.assignment.detalle
      this.createAssignmentForm.amount = item.amount
      this.createAssignmentForm.beforeDescription = item.item.calendar_operationId.summary.includes('|') ? item.item.calendar_operationId.summary : `${item.item.traveler_names}`
      this.createAssignmentForm.previewDescription = item.item.calendar_operationId.summary
      // this.addInformationDriver(parseInt(item.assignment.supplier_driver))
      // this.addInformationVehicle(parseInt(item.assignment.vehicle))
      // this.createAssignmentForm.tax_assignment = item.tax_assignment
      if (item.item.calendar_operationId.summary.includes('|')){
        const pr = item.item.calendar_operationId.summary.split('|')
        this.createAssignmentForm.agency =  pr[0]
        this.createAssignmentForm.beforeDescription = item.item.traveler_names
      }
      // console.log(this.createAssignmentForm)

    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.assignment) this.dialogDeleteAssignment = true

      this.selectedRecord = item.id
    },
    addAgency(agency){
      console.log(agency)
      const pd = this.createAssignmentForm.previewDescription.split('|')
      console.log(this.createAssignmentForm.item.traveler_names)
      let travelerName = this.createAssignmentForm.item.traveler_names ? this.createAssignmentForm.item.traveler_names.split('|') : []
      let TravelerNameComplete = undefined
      if (travelerName.length ==1){
        travelerName = travelerName[0].split(',')
        if (travelerName.length > 0){
          TravelerNameComplete = travelerName[0].trim().toUpperCase()
        }
      } else {
        if (!TravelerNameComplete) TravelerNameComplete = this.createAssignmentForm.item.reservationId.lead_traveler_name.toUpperCase()
        
        // TravelerNameComplete = travelerName[1].trim().toUpperCase()
      }
      console.log(TravelerNameComplete)

      const nombreDriver = this.supplierDriver_records.find((diver)=> diver.id == this.createAssignmentForm.supplier_driver)
      if (nombreDriver && nombreDriver.emailId) this.emailAttendent.driver = nombreDriver.emailId.email
      const nombreDriverComplete = nombreDriver ? nombreDriver.first_name : '**'

      const nombreVehicle = this.vehicles.find((vehicle)=> vehicle.id == this.createAssignmentForm.vehicle)
      const nombreVehicleComplete = nombreVehicle ? nombreVehicle.name : '**'

      const nombreGuide = this.supplierGuide_records.find((diver)=> diver.id == this.createAssignmentForm.supplier_guide)
      const nombreGuideComplete = nombreGuide ? nombreGuide.short_name :  '**'
      this.descriptionKey ++
      if (agency) {
          this.createAssignmentForm.previewDescription = `${agency.toUpperCase()} | ${TravelerNameComplete} (${nombreDriverComplete}/${nombreVehicleComplete}/${nombreGuideComplete})`
          this.createAssignmentForm.beforeDescription = `${agency.toUpperCase()} | ${TravelerNameComplete} (${nombreDriverComplete}/${nombreVehicleComplete}/${nombreGuideComplete})`
      } else {
        this.createAssignmentForm.previewDescription =
          travelerName.length ==1 ? `** | ${this.createAssignmentForm.item.traveler_names.toUpperCase()} (${nombreDriverComplete}/${nombreVehicleComplete}/${nombreGuideComplete})` : `** | ${TravelerNameComplete} (${nombreDriverComplete}/${nombreVehicleComplete}/${nombreGuideComplete})`
        console.log('quedo en blanco')
      }
    },
    addInformationDriver(item){
      console.log(item)
      let travelerName = this.createAssignmentForm.item.traveler_names ? this.createAssignmentForm.item.traveler_names.split('|') : []
      let TravelerNameComplete = undefined
      if (travelerName.length ==1){
        travelerName = travelerName[0].split(',')
        if (travelerName.length > 0){
          TravelerNameComplete = travelerName[0].trim().toUpperCase()
        }
      } else {
        if (!TravelerNameComplete) TravelerNameComplete = this.createAssignmentForm.item.reservationId.lead_traveler_name.toUpperCase()
        // TravelerNameComplete = travelerName[1].trim().toUpperCase()
      }

      const nombreDriver = this.supplierDriver_records.find((diver)=> diver.id == item)
      if (nombreDriver && nombreDriver.emailId) this.emailAttendent.driver = nombreDriver.emailId.email
      const nombreDriverComplete = nombreDriver ? nombreDriver.first_name : '**'

      const nombreVehicle = this.vehicles.find((vehicle)=> vehicle.id == this.createAssignmentForm.vehicle)
      const nombreVehicleComplete = nombreVehicle ? nombreVehicle.name : '**'

      const nombreGuide = this.supplierGuide_records.find((diver)=> diver.id == this.createAssignmentForm.supplier_guide)
      const nombreGuideComplete = nombreGuide ? nombreGuide.short_name : '**'
      this.descriptionKey ++
      if (nombreDriver){
        this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.agency ? this.createAssignmentForm.agency.toUpperCase() : '**'} | ${TravelerNameComplete} (${nombreDriverComplete}/${nombreVehicleComplete}/${nombreGuideComplete})`
      } else {
        console.log('quedo en blanco')
        this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.agency ? this.createAssignmentForm.agency.toUpperCase() : '**'} | ${TravelerNameComplete} (**/${nombreVehicleComplete}/${nombreGuideComplete})`
      }



      // if (nombreDriver) {
      //   console.log(nombreDriver)
      //   this.descriptionKey ++
      //   if(this.addInformation.vehicle) {
      //     this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.beforeDescription} (${nombreDriver.first_name}/${this.addInformation.nombreVehicle.name})`
      //   } else {
      //     if (this.btnEdithVisible) {
      //       this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.beforeDescription} (${nombreDriver.first_name}/${ nombreVehicle ? nombreVehicle.name : '' })`
      //     } else {
      //       if (nombreDriver.short_name == 'NO CHOFER'){
      //         this.descriptionKey ++
      //         if ( this.addInformation.nombreVehicle  &&  this.addInformation.nombreVehicle.name == 'No Vehiculo'){
      //           this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.item.traveler_names} (SIN TRANSPORTE)`
      //         } else {
      //           this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.beforeDescription} (SIN TRANSPORTE)`
      //         }
      //       } else {
      //         this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.previewDescription} (${nombreDriver.first_name}/`
      //       }
      //     }

      //   }
      //   this.addInformation.driver = true
      //   this.addInformation.nombreDriver = nombreDriver
      // } else {
      //   console.log('driver nulo', this.createAssignmentForm.vehicle, nombreVehicle)
      //   this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.previewDescription} | (**}/`

      // }
    },
    addInformationVehicle(item){
      console.log(item)
      let travelerName = this.createAssignmentForm.item.traveler_names ? this.createAssignmentForm.item.traveler_names.split('|') : []
      let TravelerNameComplete = undefined
      if (travelerName.length ==1){
        travelerName = travelerName[0].split(',')
        if (travelerName.length > 0){
          TravelerNameComplete = travelerName[0].trim().toUpperCase()
        }
      } else {
        if (!TravelerNameComplete) TravelerNameComplete = this.createAssignmentForm.item.reservationId.lead_traveler_name.toUpperCase()
        // TravelerNameComplete = travelerName[1].trim().toUpperCase()
      }

      const nombreDriver = this.supplierDriver_records.find((diver)=> diver.id == this.createAssignmentForm.supplier_driver)
      if (nombreDriver && nombreDriver.emailId) this.emailAttendent.driver = nombreDriver.emailId.email
      const nombreDriverComplete = nombreDriver ? nombreDriver.first_name : '**'

      const nombreVehicle = this.vehicles.find((vehicle)=> vehicle.id == item)
      const nombreVehicleComplete = nombreVehicle ? nombreVehicle.name : '**'

      const nombreGuide = this.supplierGuide_records.find((diver)=> diver.id == this.createAssignmentForm.supplier_guide)
      const nombreGuideComplete = nombreGuide ? nombreGuide.short_name : '**'

      this.descriptionKey ++
      if (nombreVehicle) {
        this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.agency ? this.createAssignmentForm.agency.toUpperCase() : '**'} | ${TravelerNameComplete} (${nombreDriverComplete}/${nombreVehicleComplete}/${nombreGuideComplete})`
      } else {
        console.log('quedo en blanco')
        this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.agency ? this.createAssignmentForm.agency.toUpperCase() : '**'} | ${TravelerNameComplete} (${nombreDriverComplete}/**/${nombreGuideComplete})`
      }
    },
    addInformationGuide(item){
      console.log(item)
      let travelerName = this.createAssignmentForm.item.traveler_names ? this.createAssignmentForm.item.traveler_names.split('|') : []
      let TravelerNameComplete = undefined
      if (travelerName.length ==1){
        travelerName = travelerName[0].split(',')
        if (travelerName.length > 0){
          TravelerNameComplete = travelerName[0].trim().toUpperCase()
        }
      } else {
        if (!TravelerNameComplete) TravelerNameComplete = this.createAssignmentForm.item.reservationId.lead_traveler_name.toUpperCase()
        // TravelerNameComplete = travelerName[1].trim().toUpperCase()
      }

      const nombreDriver = this.supplierDriver_records.find((diver)=> diver.id == this.createAssignmentForm.supplier_driver)
      if (nombreDriver && nombreDriver.emailId) this.emailAttendent.driver = nombreDriver.emailId.email
      const nombreDriverComplete = nombreDriver ? nombreDriver.first_name : '**'

      const nombreVehicle = this.vehicles.find((vehicle)=> vehicle.id == this.createAssignmentForm.vehicle)
      const nombreVehicleComplete = nombreVehicle ? nombreVehicle.name : '**'

      const nombreGuide = this.supplierGuide_records.find((diver)=> diver.id == item)
      const nombreGuideComplete = nombreGuide ? nombreGuide.short_name : '**'

      this.descriptionKey ++
      if (nombreVehicle) {
        this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.agency ? this.createAssignmentForm.agency.toUpperCase() : '**'} | ${TravelerNameComplete} (${nombreDriverComplete}/${nombreVehicleComplete}/${nombreGuideComplete})`
      } else {
        console.log('quedo en blanco')
        this.createAssignmentForm.previewDescription = `${this.createAssignmentForm.agency ? this.createAssignmentForm.agency.toUpperCase() : '**'} | ${TravelerNameComplete} (${nombreDriverComplete}/**/${nombreGuideComplete})`
      }
    },
    openCreateForm(item, selected) {
      console.log(item)
      this.dialogAssignment = true
      if (selected) {
        if (item.tour == 'ATV') this.AsigmentATV = true
        const travelerName = item.item.traveler_names
        const travelerNameComplete = travelerName ? travelerName.toUpperCase() : item.item.reservationId.lead_traveler_name.toUpperCase()

        this.createAssignmentForm.item = item.item
        this.createAssignmentForm.invoice_detail = selected
        this.createAssignmentForm.previewDescription = travelerNameComplete
        this.createAssignmentForm.beforeDescription = travelerNameComplete
        this.disableSelected = true
      }
    },
    closeForm(board) {
      this.switchCreateForm = false
      // if (board == this.boards.assignment) {
        this.dialogAssignment = false
        this.$refs.createAssignmentForm.resetValidation()
        this.createAssignmentForm = {
          info: {
            supplier_add: []
          }
        }
        this.addInformation = {}
      // }
      this.btnEdithVisible = false
      // this.isLoading = false
      this.selectedRecord = null
      this.disableSelected = false
      this.AsigmentATV = false
    },
    addAditionalGuides(){

    },
    closeDelete(board) {
      this.selectedRecord = null
      this.AsigmentATV = false
      if (board == this.boards.assignment) this.dialogDeleteAssignment = false
    },
    closeAasigByDay(){
      this.dialogAsigByDay = false
      this.dateForm = {}
    },
    async getData(board, params){
      this.loadingAssignmentTable = true
      let url =  `${board}/list`

      if (board == 'invoice_detail') {
        url =  `${board}/list?travel_date[or]=${params}&departure_date[or]=${params}&invoice[gt]=0`
        localStorage.setItem('search', JSON.stringify({board: board, params: params}))
        this.retrievedObject =  JSON.parse(localStorage.getItem('search'))

      }
      await this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: url,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(result => {
        // if (board == this.boards.assignment) this.assignment_records = result.data.data
        if (board == 'supplier') {
          this.allSupplier = result.data.data
          for (let item of result.data.data) {
            if (item.business_type_supplier == 1 || item.business_type_supplier == 5){
              this.supplierDriver_records.push(item)
            }
            if (item.business_type_supplier == 2 || item.business_type_supplier == 5){
              this.supplierGuide_records.push(item)
            }
            if (item.business_type_supplier> 2){
              this.supplierComi_records.push(item)
            }
          }
          console.log(this.supplierGuide_records)
        }
        
        if (board == 'invoice_detail') {
          this.invoiceDetail_records = result.data.data.filter((item)=> item.invoice > 0)
          this.invoiceDetail_records = this.invoiceDetail_records.map((item)=>{
            let ruta = ''
            
            if (item.product_invoice_detailId.short_name.slice(0,3) == 'R-T' && item.service_type == 'IN' && item.booking_reference.slice(0,7) != 'BR-506T') {
              ruta = `${item.pick_up_location} // ${item.drop_off_location}`
            }
            else if (item.product_invoice_detailId.short_name.slice(0,3) == 'R-T' && item.service_type == 'OUT' && item.booking_reference.slice(0,7) != 'BR-506T') {
              ruta = `${item.pick_up_location} // ${item.drop_off_location}`
            } 
            else {
              ruta = `${this.$capitalize(item.pick_up_location)} // ${this.$capitalize(item.drop_off_location)}`
            }
            let obj = {
              id: item.id,
              item: item,
              tour: item.product_invoice_detailId.short_name.slice(0,3) == 'R-T' || item.product_invoice_detailId.short_name.slice(0,3) == 'One' ? 
                item.product_invoice_detailId.short_name.slice(0,3) + '-' + item.service_type : item.product_invoice_detailId.short_name.slice(0,3),
              producto: item.product_invoice_detailId.short_name.slice(0,3) == 'R-T' || item.product_invoice_detailId.short_name.slice(0,3) == 'One' && item.product_invoice_detailId.product_name ? 
                item.product_invoice_detailId.product_name + '-' + item.service_type : item.product_invoice_detailId.short_name,
              fecha: item.product_invoice_detailId.short_name.slice(0,3) == 'Sco' ? `${item.travel_date} = ${item.departure_date}` : 
                      item.travel_date ? `${item.travel_date}`: `${item.departure_date}`,
              reserva: item.booking_reference,
              factura: item.invoice,
              descripcion: item.calendar_operationId ? item.calendar_operationId.summary: null, 
              hora: item.arrival_time ? item.arrival_time.substring(0,5) : item.departure_time ? item.departure_time.substring(0,5) : '',
              hora_recogida: item.pick_up_departure_time ? item.pick_up_departure_time : null,
              ruta: ruta,
              pax: ` ${item.pax} A ${item.pax_kids ? '+' + item.pax_kids + ' N': '' }`,
              tarifa:  item.product_invoice_detailId.short_name.slice(0,3) == 'Sco' ? `${item.quantity} X $${item.rate} = $${this.convertToMoney(item.tax ? item.tax + item.amount : item.amount)}`: `$ ${this.convertToMoney(item.tax ? item.tax + item.amount : item.amount)}`,
              assignment: item.assignment
            }
            return obj
          })
          // this.invoiceDetail_records =  this.invoiceDetail_records.filter((item) => item.descripcion != null && item.assignment )
        }
        if (board == 'tax') this.tax_records = result.data.data
        if (board == 'vehicle') this.vehicles = result.data.data
        if (board == this.boards.assignment) this.loadingAssignmentTable = false
        this.dialogAsigByDay = false
        this.loadingAssignmentTable = false
      })
      .catch(err => {
        alert("Ha ocurrido un error interno, si el error persiste comuniquese con soporte")
        this.loadingAssignmentTable = false
      })
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    tourText(item){
      return `${item.producto} FACT-${item.item.invoice} ${item.id} ${item.fecha} ${item.item.reservationId ? item.item.reservationId.lead_traveler_name : ''}`
    },
    supplierText(item){
      return `${item.short_name}    ${item.first_name}`
    },
    vehicleText(item){
      return `${item.name}`
    },
    async openExcelAtv(){
      console.log(this.invoiceDetail_records)
      const fechaReporte = JSON.parse(localStorage.getItem('search'))
      this.loadingExcel = true
      await this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'POST',
        url: `/assignment/reportXlsAtv`,
        data: {records: this.invoiceDetail_records, date: fechaReporte.params},
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        responseType: 'blob'
      })
      .then(result => {
        console.log(result.headers)
        this.loadingExcel = false
        saveAs(result.data, `Reporte Asignacion506 Dia: ${fechaReporte.params}.xlsx`)
        console.log(result)
      })
      .catch(error=>{
        this.loadingExcel = false
        alert(error)
        console.log(error)
      })
    },
    lookGuiasChoferes(){
      let choferes = []
      let guias = []

      for (let chofer of this.invoiceDetail_records){
        if (chofer.assignment){
          if (this.OptionPdfTipo == 'chofer'){
            let driver = this.supplierDriver_records.find(dri => dri.id == chofer.assignment.supplier_driver)
            if (driver) choferes.push(driver)
          }

          if (this.OptionPdfTipo == 'guia'){
            let guia = this.supplierGuide_records.find(dri => dri.id == chofer.assignment.supplier_guide)
            if (guia) guias.push(guia)
          }
        }
      }
      this.itemsChoferesGuias = [...choferes, ...guias]
    },
    openOpcionesRepAsigPDF(){
      this.dialogRepAsigPdf = true
    },
    lookName(item){
      console.log(item)

      this.selectedPerson = this.allSupplier.find(sup => sup.id == item)
    },
    async openAssignmentPdf(){
      console.log(this.invoiceDetail_records)
      const fechaReporte = JSON.parse(localStorage.getItem('search'))
      let body = {}
      // enviar segun opciones
      if (this.optionsPDF == 'general'){
        body = {
          records: this.invoiceDetail_records,
          date: fechaReporte.params,
          individual: false,
          tipo: undefined,
          general: true,
          tituloReporte:  "LISTADO DE ASIGNACIONES X DETALLE DE CHOFERES Y GUIA",
        }
      }

      if (this.optionsPDF == 'individual' && this.OptionPdfTipo == 'chofer'){
        const itemSelected = this.invoiceDetail_records.filter(item => item.assignment && item.assignment.supplier_driver == this.selectPDfChoferGuia)
        console.log(itemSelected)
        body = {
          records: itemSelected,
          date: fechaReporte.params,
          individual: true,
          tipo: this.OptionPdfTipo,
          general: false,
          tituloReporte:  `LISTADO DE ASIGNACIONES PARA `,
        }
      }
      if (this.optionsPDF == 'individual' && this.OptionPdfTipo == 'guia') {

        const itemSelected = this.invoiceDetail_records.filter(item => item.assignment && item.assignment.supplier_guide == this.selectPDfChoferGuia)
        console.log(itemSelected)
        body = {
          records: itemSelected,
          date: fechaReporte.params,
          individual: true,
          tipo: this.OptionPdfTipo,
          general: false,
          tituloReporte:  `LISTADO DE ASIGNACIONES PARA ${ this.selectedPerson ?  `${this.selectedPerson.first_name} ${this.selectedPerson.last_name}` : 'GUIA SELECCIONADO'}`,
        }
      }


      if (body && this.selectPDfChoferGuia && this.optionsPDF == 'individual'|| body && this.optionsPDF == 'general'){
        this.loadingPdf = true
        await this.$store.dispatch('verifyToken')
        axiosN506({
          method: 'POST',
          url: `/assignment/assignmentPdf`,
          data: body,
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          responseType: 'blob'
        })
        .then(result => {
          this.loadingPdf = false
          // console.log(result.headers)
          // result.data.blob().then(file => {
            const fileURL = URL.createObjectURL(result.data)
            window.open(fileURL)
          // })
          // saveAs(result.data, `Reporte Asignacion506 Dia: ${fechaReporte.params}.pdf`)
          // console.log(result)
          this.optionsPDF = 'general'
          this.OptionPdfTipo = 'chofer'
          this.selectPDfChoferGuia = null
        })
        .catch(error=>{
          this.loadingPdf = false
          alert(error)
          console.log(error)
        })
      }
      else {
        alert(`Favor escoger el ${this.OptionPdfTipo == 'chofer' ? 'Chofer' : 'Guia'}`)
      }
    }
  },
  created() {
    this.retrievedObject =  JSON.parse(localStorage.getItem('search'))
    if (this.retrievedObject) {
      this.getData(this.retrievedObject.board, this.retrievedObject.params)
    }
    this.getData(this.boards.assignment)
    this.getData('supplier', '&')
    // this.getData('invoice_detail')
    this.getData('tax')
    this.getData('vehicle')
  }
}
</script>